import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchOrganicStructurePg({dispatch}) {
    const {data, status} = await Vue.axios.get ('/pg_organic_structures');
    if (status != 200) {
      await dispatch ('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return {data, status};
  },
  async updateOrganicStructurePg ({dispatch}, organicStructurePg) {
    const formData = new FormData ();
    formData.append ('title_cover_page', organicStructurePg.title_cover_page);
    if (organicStructurePg.file_cover_page) {
      formData.append ('file_cover_page', organicStructurePg.file_cover_page);
    }
    if (organicStructurePg.file_estructure) {
      formData.append ('file_estructure', organicStructurePg.file_estructure);
    }
    const {data, status} = await Vue.axios.post (
      `/pg_organic_structures/${organicStructurePg.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch ('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos del rubro',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },
};

export default {state, mutations, actions};
