import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
  async fetchWorks({ dispatch }) {
    const { data, status } = await Vue.axios.get("/works");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener las obras', type: 'error' })
    };
    return { data, status };
  },
  async createWorks({ dispatch }, works) {
    // console.log(works);
    const { data, status } = await Vue.axios.post("/works", works);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos de la obra', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async updateWorks({ dispatch }, works) {
    const { data, status } = await Vue.axios.post(`/works/${works.id}`, works);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos de la obra', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async removeWorks({ dispatch }, works) {
    const { data, status } = await Vue.axios.delete(`/works/${works.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando la obra del sistema', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Obra eliminada correctamente', type: 'success' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
