import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
  async fetchTransparencyTypes({ dispatch }) {
    const { data, status } = await Vue.axios.get("/transparency_types");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener las transparencias', type: 'error' })
    };
    return { data, status };
  },
  async createTransparencyTypes({ dispatch }, transparencyTypes) {
    const { data, status } = await Vue.axios.post("/transparency_types", transparencyTypes);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos de transparencia', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async updateTransparencyTypes({ dispatch }, transparencyTypes) {
    const { data, status } = await Vue.axios.post(`/transparency_types/${transparencyTypes.id}`, transparencyTypes);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos de transparencia', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async removeTransparencyTypes({ dispatch }, transparencyTypes) {
    const { data, status } = await Vue.axios.delete(`/transparency_types/${transparencyTypes.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando la transparencia del sistema', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
