import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    /* Content TABLE */
    async getTransparencyContentTable({ dispatch }, idSubtype) {
        const { data, status } = await Vue.axios.get(`/transparency_content_tables/subtypes/${idSubtype}`)
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error listando el subtipo de transparencia del sistema', type: 'error' })
        }
        return { data, status };
    },
    async updateTransparencyContentTableOrder({ dispatch }, { id, newOrder }) {
      const { data, status } = await Vue.axios.put(`/transparency_content_tables/subtypes/order/${id}`, { id, newOrder })
      if (status != 200 && status != 204) return
    },
    async createTransparencyContentTable({ dispatch }, transparencySubtypes) {
        const formData = new FormData();
        formData.append('period', transparencySubtypes.period);
        formData.append('init_order', transparencySubtypes.init_order);
        formData.append('area', transparencySubtypes.area);
        formData.append('name', transparencySubtypes.name);
        formData.append('order', transparencySubtypes.order);
        formData.append('description', transparencySubtypes.description);
        formData.append('custom_date', transparencySubtypes.custom_date || "");
        formData.append('file_upload', transparencySubtypes.file_upload);
        formData.append('id_subtypes', transparencySubtypes.id_subtypes);

        const { data, status } = await Vue.axios.post("/transparency_content_tables", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateTransparencyContentTable({ dispatch }, transparencySubtypes) {
        const formData = new FormData();
        formData.append('period', transparencySubtypes.period);
        formData.append('area', transparencySubtypes.area);
        formData.append('name', transparencySubtypes.name);
        formData.append('description', transparencySubtypes.description);
        formData.append('id_subtypes', transparencySubtypes.id_subtypes);
        formData.append('custom_date', transparencySubtypes.custom_date || "");
        if (transparencySubtypes['init_order']) {
          formData.append('init_order', transparencySubtypes.init_order);
        }
        if (transparencySubtypes.file_upload)
            formData.append('file_upload', transparencySubtypes.file_upload);
        const { data, status } = await Vue.axios.post(`/transparency_content_tables/${transparencySubtypes.id}`, formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeTransparencyContentTable({ dispatch }, transparencySubtypes) {
        const { data, status } = await Vue.axios.delete(`/transparency_content_tables/${transparencySubtypes.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el subtipo del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    },

    /* Content List */
    async getTransparencyContentList({ dispatch }, idSubtype) {
        const { data, status } = await Vue.axios.get(`/transparency_content_list_header/subtypes/${idSubtype}`)
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error listando el subtipo de transparencia del sistema', type: 'error' })
        }
        return { data, status };
    },

    async createTransparencyContentListHeader({ dispatch }, transparencySubtypes) {
        const { data, status } = await Vue.axios.post("/transparency_content_list_header", transparencySubtypes);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },

    async createTransparencyContentList({ dispatch }, transparencySubtypes) {
        const formData = new FormData();
        formData.append('name', transparencySubtypes.name);
        formData.append('file_upload', transparencySubtypes.file_upload);
        formData.append('id_content', transparencySubtypes.id_content);

        const { data, status } = await Vue.axios.post("/transparency_content_list", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },

    async updateTransparencyContentListHeader({ dispatch }, transparencySubtypes) {
        const { data, status } = await Vue.axios.post(`/transparency_content_list_header/${transparencySubtypes.id}`, transparencySubtypes);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },

    async updateTransparencyContentList({ dispatch }, transparencySubtypes) {
        // console.log(transparencySubtypes)

        const formData = new FormData();
        formData.append('name', transparencySubtypes.name);
        formData.append('order', transparencySubtypes.order);
        formData.append('id_content', transparencySubtypes.id_content);

        if (transparencySubtypes.file_upload)
            formData.append('file_upload', transparencySubtypes.file_upload);

        const { data, status } = await Vue.axios.post(`/transparency_content_list/${transparencySubtypes.id}`, formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },

    async removeTransparencyContentListHeader({ dispatch }, transparencySubtypes) {
        const { data, status } = await Vue.axios.delete(`/transparency_content_list_header/${transparencySubtypes.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el subtipo del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    },

    async removeTransparencyContentList({ dispatch }, transparencySubtypes) {
        const { data, status } = await Vue.axios.delete(`/transparency_content_list/${transparencySubtypes.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el subtipo del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    },

    /* Content cards */
    async getTransparencyContentCards({ dispatch }, idSubtype) {
        const { data, status } = await Vue.axios.get(`/transparency_content_cards/subtypes/${idSubtype}`)
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error listando el subtipo de transparencia del sistema', type: 'error' })
        }
        return { data, status };
    },

    async createTransparencyContentCards({ dispatch }, transparencySubtypes) {
        const formData = new FormData();
        formData.append('name', transparencySubtypes.name);
        formData.append('file_cards', transparencySubtypes.file_cards);
        formData.append('file_upload', transparencySubtypes.file_upload);
        formData.append('id_subtypes', transparencySubtypes.id_subtypes);

        const { data, status } = await Vue.axios.post("/transparency_content_cards", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateTransparencyContentCards({ dispatch }, transparencySubtypes) {
        const formData = new FormData();
        formData.append('name', transparencySubtypes.name);
        formData.append('id_subtypes', transparencySubtypes.id_subtypes);

        if (transparencySubtypes.file_cards)
            formData.append('file_cards', transparencySubtypes.file_cards);

        if (transparencySubtypes.file_upload)
            formData.append('file_upload', transparencySubtypes.file_upload);

        const { data, status } = await Vue.axios.post(`/transparency_content_cards/${transparencySubtypes.id}`, formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeTransparencyContentCards({ dispatch }, transparencySubtypes) {
        const { data, status } = await Vue.axios.delete(`/transparency_content_cards/${transparencySubtypes.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el subtipo del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    },
}

export default { state, mutations, actions }
