import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchOfficials({ dispatch }) {
        const { data, status } = await Vue.axios.get("/officials");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
    async createOfficials({ dispatch }, official) {
        const formData = new FormData();
        formData.append('file', official.file);
        formData.append('name', official.name);
        formData.append('liability', official.liability);
        official.email && formData.append('email', official.email);
        official.telephone && formData.append('telephone', official.telephone);
        official.annexed && formData.append('annexed', official.annexed);
        official.linkedin && formData.append('linkedin', official.linkedin);
        official.transparency && formData.append('transparency', official.transparency);
        const { data, status } = await Vue.axios.post("/officials", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateOfficials({ dispatch }, official) {
        const formData = new FormData();
        formData.append('name', official.name);
        formData.append('order', official.order);
        formData.append('liability', official.liability);
        formData.append('img_official', official.img_official);
        official.email && formData.append('email', official.email);
        official.telephone && formData.append('telephone', official.telephone);
        official.annexed && formData.append('annexed', official.annexed);
        official.linkedin && formData.append('linkedin', official.linkedin);
        official.transparency && formData.append('transparency', official.transparency);
        if (official.file) {
            formData.append('file', official.file)
        }
        const { data, status } = await Vue.axios.post(`/officials/${official.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeOfficials({ dispatch }, official) {
        const { data, status } = await Vue.axios.delete(`/officials/${official.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al rubro del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }