import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchDistricts({ dispatch }) {
        const { data, status } = await Vue.axios.get("/districts");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
}

export default { state, mutations, actions }
