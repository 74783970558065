import store from "@/store/index"

export const loginMiddleware = async(to, from, next) => {
    const user = store.state.user;
    const token = store.state.token
    if (user && token) {
        next({ name: 'dashboard' })
    } else {
        next()
    }
}