import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async findAddress({ dispatch }, input) {
        const { data, status } = await Vue.axios.get(`/gmaps/places/search/${input}`);
        if (data.status != "OK") {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al buscar la dirección', type: 'error' })
        };
        return { data, status };
    },
    async getContentByPlaceId({ dispatch }, placeId) {
        const { data, status } = await Vue.axios.get(`/gmaps/places/details/${placeId}`);
        if (data.status != "OK") {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al buscar el contenido de la ubicación', type: 'error' })
        };
        return { data, status };
    }
}

export default { state, mutations, actions }