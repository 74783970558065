import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchAboutUsHome({ dispatch }) {
        const { data, status } = await Vue.axios.get("/about_us_home");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
    async updateAboutUsHome({ dispatch }, aboutUsHome) {
        const formData = new FormData();
        formData.append('title', aboutUsHome.title);
      formData.append('description', aboutUsHome.description);
      
        formData.append('btn_text', aboutUsHome.btn_text);
        formData.append('btn_url', aboutUsHome.btn_url);

        if(aboutUsHome.file) {
            formData.append('file', aboutUsHome.file)
        }

        const { data, status } = await Vue.axios.post(`/about_us_home/${aboutUsHome.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
}

export default { state, mutations, actions }
