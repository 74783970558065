<template>
  <v-fade-transition mode="out-in">
    <v-icon
      :key="$vuetify.theme.dark"
      @click="
        () => {
          saveToLocalStorage(!$vuetify.theme.dark);
          $vuetify.theme.dark = !$vuetify.theme.dark;
        }
      "
    >
      {{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}
    </v-icon>
  </v-fade-transition>
</template>

<script>
import { mdiWeatherNight, mdiWeatherSunny } from "@mdi/js";

export default {
  created() {
    const theme = localStorage.getItem("theme");
    if (!theme) return;

    if (theme == "dark") {
      this.$vuetify.theme.dark = true;
    }
  },
  setup() {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
      saveToLocalStorage(val) {
        if (val) {
          localStorage.setItem("theme", "dark");
        } else {
          localStorage.setItem("theme", "light");
        }
      },
    };
  },
};
</script>

<style></style>
