import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchAuctionsPg({ dispatch }) {
    const { data, status } = await Vue.axios.get('/pg_auctions');
    if (status != 200) {
      await dispatch('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return { data, status };
  },
  async fetchAuctionsType({ dispatch }) {
    const { data, status } = await Vue.axios.get('/category_auctions');
    if (status != 200) {
      await dispatch('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return { data, status };
  },
  async fetchAuctionsTypeById({ dispatch }, idAuction) {
    const { data, status } = await Vue.axios.get(`/category_auctions/${idAuction}`);
    if (status != 200) {
      await dispatch('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return { data, status };
  },
  async updateAuctionsPg({ dispatch }, auctionsPg) {
    const formData = new FormData();
    formData.append('title_cover_page', auctionsPg.title_cover_page);
    if (auctionsPg.file_cover_page) {
      formData.append('file_cover_page', auctionsPg.file_cover_page);
    }
    const { data, status } = await Vue.axios.post(
      `/pg_auctions/${auctionsPg.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos del rubro',
        type: 'error',
      });
    } else {
      await dispatch('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return { data, status };
  },
};

export default { state, mutations, actions };
