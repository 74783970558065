import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchAboutUsPg({ dispatch }) {
        const { data, status } = await Vue.axios.get("/pg_about_us");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
    async updateAboutUsPg({ dispatch }, aboutUsPg) {
        const formData = new FormData();
        formData.append('title_cover_page', aboutUsPg.title_cover_page);
        formData.append('title_about', aboutUsPg.title_about);
        formData.append('description_about', aboutUsPg.description_about);
        formData.append('content_mission', aboutUsPg.content_mission);
        formData.append('content_vision', aboutUsPg.content_vision);
        formData.append ('link_ubication', aboutUsPg.content_mission);

        if(aboutUsPg.file_cover_page) {
            formData.append('file_cover_page', aboutUsPg.file_cover_page)
        }
        if(aboutUsPg.file_about) {
            formData.append('file_about', aboutUsPg.file_about)
        }
        if (aboutUsPg.file_finality) {
          formData.append ('file_finality', aboutUsPg.file_finality);
        }

        const { data, status } = await Vue.axios.post(`/pg_about_us/${aboutUsPg.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
}

export default { state, mutations, actions }
