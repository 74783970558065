import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchManagedPublicPlaces({ dispatch }) {
        const { data, status } = await Vue.axios.get("/managed_public_places");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
    async createManagedPublicPlace({ dispatch }, managedPublicPlaces) {
        const formData = new FormData();
        formData.append('file_managed_public_places', managedPublicPlaces.file_managed_public_places);
        formData.append('name', managedPublicPlaces.name);
        formData.append('description', managedPublicPlaces.description);
        formData.append('btn_text', managedPublicPlaces.btn_text);
        formData.append('btn_url', managedPublicPlaces.btn_url);
        const { data, status } = await Vue.axios.post("/managed_public_places", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateManagedPublicPlace({ dispatch }, managedPublicPlaces) {
        const formData = new FormData();
        formData.append('name', managedPublicPlaces.name);
        formData.append('description', managedPublicPlaces.description);
        formData.append('btn_text', managedPublicPlaces.btn_text);
        formData.append('btn_url', managedPublicPlaces.btn_url);
        if(managedPublicPlaces.file_managed_public_places) {
            formData.append('file_managed_public_places', managedPublicPlaces.file_managed_public_places)
        }
        const { data, status } = await Vue.axios.post(`/managed_public_places/${managedPublicPlaces.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeManagedPublicPlace({ dispatch }, managedPublicPlaces) {
        const { data, status } = await Vue.axios.delete(`/managed_public_places/${managedPublicPlaces.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al rubro del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }