import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchAdministrationSanitizationPg({dispatch}) {
    const {data, status} = await Vue.axios.get ('/administration_sanitization');
    if (status != 200) {
      await dispatch ('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return {data, status};
  },
  async updateAdministrationSanitizationPg ({dispatch}, administrationSanitizationPg) {
    const formData = new FormData ();
    formData.append ('title_cover_page', administrationSanitizationPg.title_cover_page);
    formData.append ('state_total', administrationSanitizationPg.state_total);
    formData.append ('states_sanitized', administrationSanitizationPg.states_sanitized);
    formData.append ('states_in_process_sanitization', administrationSanitizationPg.states_in_process_sanitization);
    formData.append ('sanitization_description', administrationSanitizationPg.sanitization_description);
    formData.append ('administration_description', administrationSanitizationPg.administration_description);
    formData.append ('administrated_state_commerce', administrationSanitizationPg.administrated_state_commerce);
    formData.append ('administrated_state_sanitary_facilities', administrationSanitizationPg.administrated_state_sanitary_facilities);
    formData.append ('administrated_state_dwelling', administrationSanitizationPg.administrated_state_dwelling);
    if (administrationSanitizationPg.file_cover_page) {
      formData.append ('file_cover_page', administrationSanitizationPg.file_cover_page);
    }
    const {data, status} = await Vue.axios.post (
      `/administration_sanitization/${administrationSanitizationPg.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch ('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos del rubro',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },
};

export default {state, mutations, actions};
