import Vue from "vue"

const state = {}

const mutations = {}

const actions = {
  async fetchTechnologicalDevelopmentsAll({ dispatch }) {
    const { data, status } = await Vue.axios.get("/all_technological_developments");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener las redes sociales', type: 'error' })
    };
    return { data, status };
  },
  async createTechnologicalDevelopmentsAll({ dispatch }, allTechnologicalDevelopments) {
    // console.log(allTechnologicalDevelopments);
    const formData = new FormData();
    formData.append('file_technological_developments', allTechnologicalDevelopments.file_technological_developments);
    formData.append('title_technological_developments', allTechnologicalDevelopments.title_technological_developments);
    formData.append('category', allTechnologicalDevelopments.category);
    formData.append('btn_text', allTechnologicalDevelopments.btn_text);
    formData.append('btn_url', allTechnologicalDevelopments.btn_url);
    formData.append('id_category', allTechnologicalDevelopments.id_category);
    const { data, status } = await Vue.axios.post("/all_technological_developments", formData);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando la red social', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },

  async updateTechnologicalDevelopmentsAll({ dispatch }, allTechnologicalDevelopments) {
    const formData = new FormData();
    // console.log(allTechnologicalDevelopments);
    formData.append('title_technological_developments', allTechnologicalDevelopments.title_technological_developments);
    formData.append('category', allTechnologicalDevelopments.category);
    formData.append('btn_text', allTechnologicalDevelopments.btn_text);
    formData.append('btn_url', allTechnologicalDevelopments.btn_url);
    formData.append('id_category', allTechnologicalDevelopments.id_category);
    if (allTechnologicalDevelopments.file_technological_developments) {
      formData.append('file_technological_developments', allTechnologicalDevelopments.file_technological_developments)
    }
    const { data, status } = await Vue.axios.post(`/all_technological_developments/${allTechnologicalDevelopments.id}`, formData);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando la red social', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },

  async removeTechnologicalDevelopmentsAll({ dispatch }, allTechnologicalDevelopments) {
    const { data, status } = await Vue.axios.delete(`/all_technological_developments/${allTechnologicalDevelopments.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando la red social del sistema', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
