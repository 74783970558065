import Vue from 'vue'
import VueRouter from 'vue-router'
import { verifyAuth } from "@/middlewares/auth"
import { loginMiddleware } from "@/middlewares/login"

Vue.use(VueRouter)

const routes = [{
        path: '/',
        redirect: 'dashboard',
    },
    {
        path: '/auth/login',
        name: 'auth-login',
        component: () =>
            import ('@/views/pages/Login.vue'),
        meta: {
            layout: 'blank',
        },
        beforeEnter: loginMiddleware
    },

    {
        path: '/dashboard',
        name: 'dashboard',
        component: () =>
            import ('@/views/dashboard/Dashboard.vue'),
    },
    //?Pages
    {
        path: '/about-us-pg',
        name: 'about-us-pg',
        component: () =>
            import ('@/views/pages/about-us-pg/AboutUsPg.vue'),
    },
    {
        path: '/projects-pg',
        name: 'projects-pg',
        component: () =>
            import ('@/views/pages/projects-pg/ProjectsPg.vue'),
    },
    {
        path: '/auctions-pg',
        name: 'auctions-pg',
        component: () =>
            import ('@/views/pages/auctions-pg/AuctionsPg.vue'),
    },
    {
        path: '/releases-pg',
        name: 'releases-pg',
        component: () =>
            import ('@/views/pages/releases-pg/ReleasesPg.vue'),
    },
    {
        path: '/organic-structure-pg',
        name: 'organic-structure-pg',
        component: () =>
            import ('@/views/pages/organic-structure-pg/OrganicStructurePg.vue'),
    },
    {
        path: '/execution-public-works-pg',
        name: 'execution-public-works-pg',
        component: () =>
            import ('@/views/pages/execution-public-works-pg/ExecutionPublicWorksPg.vue'),
    },
    {
        path: '/administration-sanitization-pg',
        name: 'administration-sanitization-pg',
        component: () =>
            import ('@/views/pages/administration-sanitization-pg/AdministrationSanitizationPg.vue'),
    },
    {
        path: '/technological-developments-pg',
        name: 'technological-developments-pg',
        component: () =>
            import ('@/views/pages/technological-developments-pg/TechnologicalDevelopmentsPg.vue'),
    },
    {
        path: '/officials-pg',
        name: 'officials-pg',
        component: () =>
            import ('@/views/pages/officials-pg/OfficialsPg.vue'),
  },
  {
    path: '/denunciation-pg',
    name: 'denunciation-pg',
    component: () =>
      import('@/views/pages/denunciations-pg/DenunciationsPg.vue'),
  },
    {
        path: '/press-notes-pg',
        name: 'press-notes-pg',
        component: () =>
            import ('@/views/pages/press-notes-pg/PressNotesPg.vue'),
    },
    {
        path: '/marks-legals-pg',
        name: 'marks-legals-pg',
        component: () =>
            import ('@/views/pages/marks-legals-pg/MarksLegalsPg.vue'),
    },
    {
        path: '/publishing-funds-pg',
        name: 'publishing-funds-pg',
        component: () =>
            import ('@/views/pages/publishing-funds/PublishingFundsPg.vue'),
    },
    {
        path: '/transparency',
        name: 'transparency-types',
        component: () =>
          import ('@/views/pages/transparency-types/TransparencyTypes.vue'),
    },
    {
        path: '/transparency/type/:id',
        name: 'transparency-subtypes',
        component: () =>
          import ('@/views/pages/transparency-sub-types/TransparencySubTypes.vue'),
    },
    {
        path: '/transparency/type/:type/type-content/:subtype',
        name: 'transparency-subtypes-types',
        component: () =>
          import ('@/views/pages/transparency-subtypes-types/TransparencySubtypesTypes.vue'),
    },

    //?Configs
    {
      path: '/basic-information',
      name: 'basic-information',
        component: () =>
            import ('@/views/pages/basic-information/BasicInformation.vue'),
    },
    {
        path: '/usuarios',
        name: 'users-page',
        component: () =>
            import ('@/views/pages/users/Users.vue'),
    },
    {
        path: '/reels',
        name: 'reels-page',
        component: () =>
            import ('@/views/pages/reels/Reels.vue'),
    },
    {
        path: '/areas',
        name: 'areas-page',
        component: () =>
            import ('@/views/pages/areas/Areas.vue'),
    },
    {
        path: '/about-us-home',
        name: 'about-us-home',
        component: () =>
            import ('@/views/pages/about-us-home/AboutUsHome.vue'),
    },
    {
        path: '/some-projects',
        name: 'some-projects',
        component: () =>
            import ('@/views/pages/some-projects/SomeProjects.vue'),
    },
    {
        path: '/auctions',
        name: 'auctions',
        component: () =>
            import ('@/views/pages/auction/AuctionCategory.vue'),
    },
    {
        path: '/auctions/category/:idCategory',
        name: 'auctions-category',
        component: () =>
            import ('@/views/pages/auction/Auction.vue'),
    },
    {
        path: '/auctions/category/:idCategory/form',
        name: 'auctions-category-form',
        component: () =>
            import ('@/views/pages/auction/AuctionEdited.vue'),
    },

    {
        path: '/auctions/category/:idCategory/form/:idAuction',
        name: 'auctions-category-form-edited',
        component: () =>
            import ('@/views/pages/auction/AuctionEdited.vue'),
    },

  {
    path: '/technological-developments-category',
    name: 'technological-developments-category',
    component: () =>
      import('@/views/pages/technological-developments-category/TechnologicalDevelopmentsCategory.vue'),
  },


    //?COMUNICATED
    {
        path: '/press-notes',
        name: 'press-notes',
        component: () =>
            import ('@/views/pages/press-notes/PressNotes.vue'),
    },
    {
        path: '/releases',
        name: 'releases',
        component: () =>
            import ('@/views/pages/releases/Releases.vue'),
    },
    {
        path: '/releases/:slug',
        name: 'releases-slug',
        component: () =>
            import ('@/views/pages/releases/ViewImageReleases.vue'),
    },
    {
        path: '/pop-ups',
        name: 'pop-ups',
        component: () =>
            import ('@/views/pages/pop-ups/PopUps.vue'),
    },
    {
      path: '/works',
      name: 'works',
      component: () =>
        import('@/views/pages/works/Works.vue'),
    },
    {
        path: '/books',
        name: 'books',
        component: () =>
          import('@/views/pages/books/Books.vue'),
      },
      {
        path: '/collections',
        name: 'collections',
        component: () =>
          import('@/views/pages/collections/Collections.vue'),
      },

  // {
  //   path: '/works',
  //   name: 'works',
  //   component: () =>
  //     import('@/views/pages/imagesWorks/ImagesWorks.vue'),
  // },
    // Others
    {
        path: '/configuracion-cuenta',
        name: 'pages-account-settings',
        component: () =>
            import ('@/views/pages/account-settings/AccountSettings.vue'),
    },
    {
        path: '/typography',
        name: 'typography',
        component: () =>
            import ('@/views/typography/Typography.vue'),
    },
    {
        path: '/icons',
        name: 'icons',
        component: () =>
            import ('@/views/icons/Icons.vue'),
    },
    {
        path: '/cards',
        name: 'cards',
        component: () =>
            import ('@/views/cards/Card.vue'),
    },
    {
        path: '/simple-table',
        name: 'simple-table',
        component: () =>
            import ('@/views/simple-table/SimpleTable.vue'),
    },
    {
        path: '/form-layouts',
        name: 'form-layouts',
        component: () =>
            import ('@/views/form-layouts/FormLayouts.vue'),
    },


    {
        path: '/pages/register',
        name: 'pages-register',
        component: () =>
            import ('@/views/pages/Register.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '/error-404',
        name: 'error-404',
        component: () =>
            import ('@/views/Error.vue'),
        meta: {
            layout: 'blank',
        },
    },
    {
        path: '*',
        redirect: 'error-404',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach(verifyAuth)

export default router
