import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchMarksLegalsPg({dispatch}) {
    const {data, status} = await Vue.axios.get ('/marks_legals');
    if (status != 200) {
      await dispatch ('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return {data, status};
  },
  async updateMarksLegalsPg ({dispatch}, marksLegalsPg) {
    const formData = new FormData ();
    formData.append ('regulation', marksLegalsPg.regulation);
    if (marksLegalsPg.upload) {
      formData.append ('upload', marksLegalsPg.upload);
    }
    const {data, status} = await Vue.axios.post (
      `/marks_legals/${marksLegalsPg.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch ('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos del rubro',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },
};

export default {state, mutations, actions};
