import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchTechnologicalDevelopmentsPg({dispatch}) {
    const {data, status} = await Vue.axios.get ('/technological_developments');
    if (status != 200) {
      await dispatch ('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los rubros',
        type: 'error',
      });
    }
    return {data, status};
  },
  async updateTechnologicalDevelopmentsPg ({dispatch}, technologicalDevelopmentsPg) {
    const formData = new FormData ();
    formData.append ('title_cover_page', technologicalDevelopmentsPg.title_cover_page);
    if (technologicalDevelopmentsPg.file_cover_page) {
      formData.append ('file_cover_page', technologicalDevelopmentsPg.file_cover_page);
    }
    const {data, status} = await Vue.axios.post (
      `/technological_developments/${technologicalDevelopmentsPg.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch ('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos del rubro',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },
};

export default {state, mutations, actions};
