import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
  async createTransparencySubTypes({ dispatch }, transparencySubtypes) {
    const { data, status } = await Vue.axios.post("/transparency_subtypes", transparencySubtypes);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del subtipo', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async updateTransparencySubTypes({ dispatch }, transparencySubtypes) {
    const { data, status } = await Vue.axios.post(`/transparency_subtypes/${transparencySubtypes.id}`, transparencySubtypes);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del subtipo', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async removeTransparencySubTypes({ dispatch }, transparencySubtypes) {
    const { data, status } = await Vue.axios.delete(`/transparency_subtypes/${transparencySubtypes.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el subtipo del sistema', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
    }
    return { data, status };
  },

  async getIdTypes({ dispatch }, id_types) {
    
    const { data, status } = await Vue.axios.get(`/transparency_subtypes/type/${id_types}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error listando el subtipo de transparencia del sistema', type: 'error' })
    }
    return { data, status };
  },
  
  async getSubtype({ dispatch }, subtype) {
    
    const { data, status } = await Vue.axios.get(`/transparency_subtypes/${subtype}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error listando el subtipo de transparencia del sistema', type: 'error' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
