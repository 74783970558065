const tokenKey = "token"
const expiresKey = "expires"
const gapMilliseconds = 1000 * 60 * 2 // 2 minutes
const ls = window.localStorage || localStorage
let timeOutWatch = null

function getToken() {
  return ls.getItem(tokenKey)
}

function getExpiresTime() {
  return ls.getItem(expiresKey)
}

function validateKeys() {
  return ls.getItem(tokenKey) && ls.getItem(expiresKey)
}

function watchSessionBeforeExpire(callback, paramsCallback) {
  return new Promise((resolve, reject) => {

    if (timeOutWatch) clearTimeout(timeOutWatch)

    if (validateKeys()) {
      const token = getToken()
      const expiresTime = getExpiresTime()

      const difference = parseFloat(expiresTime) - new Date().getTime()

      if (difference < 0 || difference < gapMilliseconds) {
        reject(new Error('La sesión ha expirado'))
      }

      const timeToRefresh = parseInt(difference - gapMilliseconds)
      console.log("Se refrescará en " + timeToRefresh / 1000 + " segundos");
      timeOutWatch = setTimeout(async () => {
        await callback(paramsCallback)
        resolve('Sesión refrescada')
      }, timeToRefresh);
    } else {
      clearTimeout(timeOutWatch)
      reject('La sesión ha expirado aa')
    }
  })
}

export const initSessionWatch = ({ callback, paramsCallback, onErrorCallback }) => {
  try {
    watchSessionBeforeExpire(callback, paramsCallback)
      .then(success => {
        initSessionWatch({ callback, paramsCallback, onErrorCallback })
        console.log(success)
      })
      .catch(async (error) => {
        if (onErrorCallback) {
          await onErrorCallback()
        }
        alert(error)
      })
  } catch (error) {
    onErrorCallback()
  }
}

export const clearSessionWatch = () => {
  clearTimeout(timeOutWatch)
  console.log("Sessión culminada", timeOutWatch)
}
