import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchDenunciations({ dispatch }) {
    const { data, status } = await Vue.axios.get('/denuntiation');
    if (status != 200) {
      await dispatch('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener el card',
        type: 'error',
      });
    }
    return { data, status };
  },

  async createDenunciations({ dispatch }, denunciation) {
    const { data, status } = await Vue.axios.post(
      '/denuntiation',
      denunciation.formData
    );
    if (status != 200 && status != 201) {
      await dispatch('createResponseMessage', {
        message: data.message || 'Ocurrió un error guardando el card',
        type: 'error',
      });
    } else {
      await dispatch('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return { data, status };
  },

  async updateDenunciations({ dispatch }, denunciation) {
    const { data, status } = await Vue.axios.post(
      `/denuntiation/${denunciation.id}`,
      denunciation.formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando el card',
        type: 'error',
      });
    } else {
      await dispatch('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return { data, status };
  },

  async removeDenunciations({ dispatch }, denunciation) {
    const { data, status } = await Vue.axios.delete(
      `/denuntiation/${denunciation.id}`
    );
    if (status != 200 && status != 204) {
      await dispatch('generateSnackBarMessage', {
        message: data.message ||
          'Ocurrió un error eliminando el card',
        type: 'error',
      });
    } else {
      await dispatch('generateSnackBarMessage', {
        message: data.message || 'Card eliminado correctamente',
        type: 'success',
      });
    }
    return { data, status };
  },


};

export default { state, mutations, actions };
