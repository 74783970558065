<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
}
</script>
<style>
*,
body {
  box-sizing: border-box;
}
.swal2-modal {
  font-family: 'Segoe UI', 'Inter', sans-serif !important;
}
.swal2-html-container {
  font-size: 15px !important;
}
.swal2-actions {
  font-size: 14.5px;
}
tr.ghost-drag-item {
  opacity: 0.5;
  background: #f7fafc !important;
  border: 1px solid #4299e1 !important;
}
.dragg-item, .dragg-item-list {
  cursor: move;
}
.short-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  height: -webkit-calc(3 * 1.8);
  height: calc(3 * 1.8);
  overflow-y: hidden;
  transition: height ease-in-out 0.25s;
}
</style>
