import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchDenunciationsPg({ dispatch }) {
    const { data, status } = await Vue.axios.get('/pg_denuntiations');
    if (status != 200) {
      await dispatch('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los datos',
        type: 'error',
      });
    }
    return { data, status };
  },
  async updateDenunciationsPg({ dispatch }, denunciationsPg) {
    const formData = new FormData();
    formData.append('title_cover_page', denunciationsPg.title_cover_page);
    if (denunciationsPg.file_cover_page) {
      formData.append('file_cover_page', denunciationsPg.file_cover_page);
    }
    formData.append('title', denunciationsPg.title);
    formData.append('title_card', denunciationsPg.title_card);
    if (denunciationsPg.file_card) {
      formData.append('file_card', denunciationsPg.file_card);
    }
    formData.append('content_card', denunciationsPg.content_card);
    const { data, status } = await Vue.axios.post(
      `/pg_denuntiations/${denunciationsPg.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos',
        type: 'error',
      });
    } else {
      await dispatch('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return { data, status };
  },
};

export default { state, mutations, actions };
