import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchPressNotes({dispatch}) {
    const {data, status} = await Vue.axios.get ('/press_notes');
    if (status != 200) {
      await dispatch ('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener las notas de prensa',
        type: 'error',
      });
    }
    return {data, status};
  },

  async createPressNotes ({dispatch}, pressNotesCards) {
    const {data, status} = await Vue.axios.post (
      '/press_notes',
      pressNotesCards.formData
    );
    if (status != 200 && status != 201) {
      await dispatch ('createResponseMessage', {
        message: data.message || 'Ocurrió un error guardando la nota de prensa',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },

  async updatePressNotes ({dispatch}, pressNotesCards) {
    const {data, status} = await Vue.axios.post (
      `/press_notes/${pressNotesCards.id}`,
      pressNotesCards.formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch ('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando las notas de prensa',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },

  async removePressNotes ({dispatch}, pressNotesCards) {
    const {data, status} = await Vue.axios.delete (
      `/press_notes/${pressNotesCards.id}`
    );
    if (status != 200 && status != 204) {
      await dispatch ('generateSnackBarMessage', {
        message: data.message ||
          'Ocurrió un error eliminando la nota de prensa',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Nota de prensa eliminado correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },
};

export default {state, mutations, actions};
