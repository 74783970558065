import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

const http = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}/api`
})

http.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        if (!error.response) {
            error.response = {};
            error.response.status = 500;
            error.response.data = { error: { message: "No se puede conectar con el servidor" } }
        }
        return error.response || error;
    }
);

Vue.use(VueAxios, http);

export default http;