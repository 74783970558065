import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
  async fetchTechnologicalTechnologies({ dispatch }) {
    const { data, status } = await Vue.axios.get("/technological_developments_technologies");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener la categoria de la tecnologia', type: 'error' })
    };
    return { data, status };
  },
  async createTechnologicalTechnologies({ dispatch }, technologicalTechnologies) {
    const { data, status } = await Vue.axios.post("/technological_developments_technologies", technologicalTechnologies.formData);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando la categoria de la tecnologia', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async updateTechnologicalTechnologies({ dispatch }, technologicalTechnologies) {
    const { data, status } = await Vue.axios.post(`/technological_developments_technologies/${technologicalTechnologies.id}`, technologicalTechnologies.formData);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos de la categoria de la tecnologia', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async removeTechnologicalTechnologies({ dispatch }, technologicalTechnologies) {
    const { data, status } = await Vue.axios.delete(`/technological_developments_technologies/${technologicalTechnologies.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando la categoria de la tecnologia seleccionada', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Categoria de la tecnologia removido correctamente', type: 'success' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
