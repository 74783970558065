import { ref } from "@vue/composition-api";
import store from "@/store/index"
import router from "@/router/index"

const useAuth = () => {
    const email = ref("");
    const password = ref("");
    const loading = ref(false);
    const formLogin = ref(null)

    const validateForm = () => {
        return formLogin.value.validate();
    }

    const signIn = async() => {
        if (!validateForm()) return;
        loading.value = true;
        const formData = new FormData();
        formData.append("email", email.value)
        formData.append("password", password.value)
        const { data, status } = await store.dispatch("signIn", formData);
        loading.value = false;
        if (status != 200) return;
        store.dispatch("clearResponseMessage")
        router.push({ name: 'dashboard' })
    }

    const logout = async() => {
        await store.dispatch("logout");
        router.push({ name: 'auth-login' }).catch(err => {})
    }

    return {
        email,
        password,
        loading,
        formLogin,
        //
        signIn,
        logout
    }
}


export default useAuth;