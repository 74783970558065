<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-3 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-img
          :src="
            $vuetify.theme.dark
              ? require('@/assets/images/logos/logo-emilima-blanco.png')
              : require('@/assets/images/logos/logo-emilima-color.png')
          "
          max-height="80px"
          max-width="110px"
          height="46"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <!-- <v-slide-x-transition>
          <h2 class="app-title text--primary">
            Emilima
          </h2>
        </v-slide-x-transition> -->
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link title="Inicio" :to="{ name: 'dashboard' }" :icon="icons.mdiHomeOutline"></nav-menu-link>
      <!--       <nav-menu-link
        title="Mi perfíl"
        :to="{ name: 'pages-account-settings' }"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link> -->

      <!-- <nav-menu-group
        title="Configuraciones"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group> -->

      <!--//?HOME -->
      <nav-menu-section-title title="HOME"></nav-menu-section-title>

      <nav-menu-link title="Reels" :to="{ name: 'reels-page' }" :icon="icons.mdiImageMultipleOutline"></nav-menu-link>
      <nav-menu-link
        title="Datos Generales"
        :to="{ name: 'about-us-home' }"
        :icon="icons.mdiInformationOutline"
      ></nav-menu-link>
      <nav-menu-link title="Servicios" :to="{ name: 'areas-page' }" :icon="icons.mdiHandHeart"></nav-menu-link>
      <nav-menu-link
        title="Algunos Proyectos"
        :to="{ name: 'some-projects' }"
        :icon="icons.mdiAccountHardHat"
      ></nav-menu-link>

      <!--//?PAGINAS -->
      <nav-menu-section-title title="PÁGINAS"></nav-menu-section-title>
      <nav-menu-link
        title="Datos Generales"
        :to="{ name: 'about-us-pg' }"
        :icon="icons.mdiInformationOutline"
      ></nav-menu-link>

      <nav-menu-link
        title="Estructura Organica"
        :to="{ name: 'organic-structure-pg' }"
        :icon="icons.mdiSitemap"
      ></nav-menu-link>

      <nav-menu-link title="Funcionarios" :to="{ name: 'officials-pg' }" :icon="icons.mdiAccountTie"></nav-menu-link>

            <nav-menu-link title="Denuncias" :to="{ name: 'denunciation-pg' }" :icon="icons.mdiBookCancel "></nav-menu-link>

      <nav-menu-link
        title="Administración y Saneamiento"
        :to="{ name: 'administration-sanitization-pg' }"
        :icon="icons.mdiFolderHome"
      ></nav-menu-link>

      <nav-menu-link title="Subastas" :to="{ name: 'auctions-pg' }" :icon="icons.mdiGavel"></nav-menu-link>

      <nav-menu-link
        title="Desarrollo Tecnologico"
        :to="{ name: 'technological-developments-pg' }"
        :icon="icons.mdiRobotIndustrial"
      ></nav-menu-link>

      <nav-menu-link
        title="Obras Públicas"
        :to="{ name: 'execution-public-works-pg' }"
        :icon="icons.mdiAccountHardHat"
      ></nav-menu-link>

      <nav-menu-link
        title="Fondo Editorial"
        :to="{ name: 'publishing-funds-pg' }"
        :icon="icons.mdiBookshelf"
      ></nav-menu-link>

      <!--       <nav-menu-link
        title="Proyectos"
        :to="{ name: 'projects-pg' }"
        :icon="icons.mdiBookOpenVariant"
      ></nav-menu-link> -->

      <!--       <nav-menu-link
        title="Fondo Editorial"
        :to="{ name: 'editorial-funds-pg' }"
        :icon="icons.mdiBookshelf"
      ></nav-menu-link> -->

      <nav-menu-link
        title="Notas de Prensa"
        :to="{ name: 'press-notes-pg' }"
        :icon="icons.mdiNewspaperVariantMultipleOutline"
      ></nav-menu-link>

     <nav-menu-link
        title="Comunicados"
        :to="{ name: 'releases-pg' }"
        :icon="icons.mdiBullhornOutline"
      ></nav-menu-link>
     <nav-menu-link
        title="Transparencia"
        :to="{ name: 'transparency-types' }"
        :icon="icons.mdiAccountSearch"
      ></nav-menu-link>

      <!--//?PRENSA -->
      <nav-menu-section-title title="PRENSA"></nav-menu-section-title>
      <nav-menu-link title="COMUNICADOS" :to="{ name: 'releases' }" :icon="icons.mdiChatAlert"></nav-menu-link>
      <nav-menu-link
        title="NOTAS DE PRENSA"
        :to="{ name: 'press-notes' }"
        :icon="icons.mdiNewspaperVariant"
      ></nav-menu-link>

      <!--//?CONFIGURACION -->
      <nav-menu-section-title title="CONFIGURACIONES"></nav-menu-section-title>
      <nav-menu-link
        title="Información Basica"
        :to="{ name: 'basic-information' }"
        :icon="icons.mdiInformation"
      ></nav-menu-link>
      <nav-menu-link
        title="Usuarios"
        :to="{ name: 'users-page' }"
        :icon="icons.mdiAccountMultiplePlusOutline"
      ></nav-menu-link>
      <nav-menu-link title="Subastas" :to="{ name: 'auctions' }" :icon="icons.mdiGavel"></nav-menu-link>
      <nav-menu-link title="Pop Ups" :to="{ name: 'pop-ups' }" :icon="icons.mdiImageMultipleOutline"></nav-menu-link>
      <nav-menu-link title="Obras Públicas" :to="{ name: 'works' }" :icon="icons.mdiBriefcaseAccount"></nav-menu-link>
      <nav-menu-link title="Libros" :to="{ name: 'books' }" :icon="icons.mdiBriefcaseAccount"></nav-menu-link>
      <nav-menu-link title="Collecciones" :to="{ name: 'collections' }" :icon="icons.mdiBriefcaseAccount"></nav-menu-link>
      <nav-menu-link title="Categoria desarrollo tecnologico" :to="{ name: 'technological-developments-category' }" :icon="icons.mdiRobotConfused"></nav-menu-link>
      <!-- <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link> -->
    </v-list>
    <!-- <a
      href="https://themeselection.com/products/materio-vuetify-vuejs-admin-template"
      target="_blank"
      rel="nofollow"
    >
      <v-img
        :src="require(`@/assets/images/pro/upgrade-banner-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
        alt="upgrade-banner"
        transition="scale-transition"
        class="upgrade-banner mx-auto"
        max-width="230"
      ></v-img>
    </a> -->
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccountMultiplePlusOutline,
  mdiImageMultipleOutline,
  mdiOfficeBuilding,
  mdiAccountGroup,
  mdiBookOpenVariant,
  mdiGavel,
  mdiBullhornOutline,
  mdiSitemap,
  mdiAccountHardHat,
  mdiFolderHome,
  mdiRobotIndustrial,
  mdiAccountTie,
  mdiScaleBalance,
  mdiBookshelf,
  mdiNewspaperVariantMultipleOutline,
  mdiInformationOutline,
  mdiHandHeart,
  mdiZipBox,
  mdiFolderZip,
  mdiChatAlert,
  mdiNewspaperVariant,
  mdiBriefcaseAccount,
  mdiAccountSearch,
  mdiInformation,
  mdiRobotConfused,
  mdiBookCancel,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccountMultiplePlusOutline,
        mdiImageMultipleOutline,
        mdiOfficeBuilding,
        mdiAccountGroup,
        mdiBookOpenVariant,
        mdiGavel,
        mdiBullhornOutline,
        mdiSitemap,
        mdiAccountHardHat,
        mdiFolderHome,
        mdiRobotIndustrial,
        mdiAccountTie,
        mdiScaleBalance,
        mdiBookshelf,
        mdiNewspaperVariantMultipleOutline,
        mdiInformationOutline,
        mdiHandHeart,
        mdiZipBox,
        mdiFolderZip,
        mdiChatAlert,
        mdiNewspaperVariant,
        mdiBriefcaseAccount,
        mdiAccountSearch,
        mdiInformation,
        mdiRobotConfused,
        mdiBookCancel
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

.v-list-item--active.theme--dark {
  background: linear-gradient(98deg, #2c9b93, #00a39c 94%) !important;
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
