import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchBooks({ dispatch }) {
        const { data, status } = await Vue.axios.get("/books");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
    async createBook({ dispatch }, book) {
        const formData = new FormData();
        formData.append('title', book.title);
        formData.append('description', book.description);
        formData.append('buy_url', book.buy_url);
        if (book.id_collection) {
            formData.append('id_collection', book.id_collection);

        }
        formData.append('file_book', book.file_book);
        const { data, status } = await Vue.axios.post("/books", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateBook({ dispatch }, book) {
        const formData = new FormData();
        formData.append('title', book.title);
        formData.append('description', book.description);
        formData.append('buy_url', book.buy_url);
        if (book.id_collection) {
            formData.append('id_collection', book.id_collection);
        }
        if (book.file_book) {
            formData.append('file_book', book.file_book)
        }
        const { data, status } = await Vue.axios.post(`/books/${book.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeBook({ dispatch }, book) {
        const { data, status } = await Vue.axios.delete(`/books/${book.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al rubro del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }