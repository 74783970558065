import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async saveFile({ dispatch }, file) {
        const formData = new FormData();
        formData.append('file_press_notes', file.file_press_notes)
        const { data, status } = await Vue.axios.post("/img_press_notes", formData);
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al guardar la imagen', type: 'error' })
        };
        return { data, status };
    },
}

export default { state, mutations, actions }