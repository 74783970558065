import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchContentByAuction({ dispatch }, idAuction) {
        const { data, status } = await Vue.axios.get(`/auctions_content/auctions_id/${idAuction}`);
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },

    async createContentAuction({ dispatch }, auction) {
        const formData = new FormData();
        formData.append('file_auctions_contents', auction.image);
        formData.append('content_auctions_contents', auction.content_auctions_contents);
        formData.append('title_auctions_contents', auction.title_auctions_contents);
        formData.append('id_auctions', auction.id_auctions);
        const { data, status } = await Vue.axios.post("/auctions_content", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateContentAuction({ dispatch }, auction) {
        const formData = new FormData();
        formData.append('content_auctions_contents', auction.content_auctions_contents);
        formData.append('title_auctions_contents', auction.title_auctions_contents);
        formData.append('id_auctions', auction.id_auctions);
        if (auction.image) {
            formData.append('file_auctions_contents', auction.image)
        }
        const { data, status } = await Vue.axios.post(`/auctions_content/${auction.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeContentAuction({ dispatch }, auction) {
        const { data, status } = await Vue.axios.delete(`/auctions_content/${auction.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al rubro del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }