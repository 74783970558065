import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
  async fetchBasicInformation({ dispatch }) {
    const { data, status } = await Vue.axios.get("/basic_information");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener la información basica', type: 'error' })
    };
    return { data, status };
  },

  async updateBasicInformation({ dispatch }, basicInformation) {
    const formData = new FormData();
    formData.append('link_table_parts', basicInformation.link_table_parts);
    formData.append('link_portal_transparency', basicInformation.link_portal_transparency);
    formData.append('address', basicInformation.address);
    formData.append('telephone', basicInformation.telephone);
    formData.append('email', basicInformation.email);

    if (basicInformation.file_color) {
      formData.append('file_color', basicInformation.file_color)
    }
    if (basicInformation.file_white) {
      formData.append('file_white', basicInformation.file_white)
    }
    if (basicInformation.file_municipality) {
      formData.append('file_municipality', basicInformation.file_municipality);
    }

    const { data, status } = await Vue.axios.post(`/basic_information/${basicInformation.id}`, formData);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos de la información basica', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
}

export default { state, mutations, actions }
