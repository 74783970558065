import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchDenunciationsQuestions({ dispatch }) {
    const { data, status } = await Vue.axios.get('/denuntiation_question');
    if (status != 200) {
      await dispatch('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener los datos',
        type: 'error',
      });
    }
    return { data, status };
  },
  async updateDenunciationsQuestions({ dispatch }, denunciationsQuestions) {
    const formData = new FormData();
    formData.append('title', denunciationsQuestions.title);
    if (denunciationsQuestions.file) {
      formData.append('file', denunciationsQuestions.file);
    }
    formData.append('content', denunciationsQuestions.content);
    const { data, status } = await Vue.axios.post(
      `/denuntiation_question/${denunciationsQuestions.id}`,
      formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando los datos',
        type: 'error',
      });
    } else {
      await dispatch('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return { data, status };
  },
};

export default { state, mutations, actions };
