import Vue from "vue"
// este store solo me servirá para almacenar funciones

const state = {}

const mutations = {}

const actions = {
  async fetchImagesWorks({ dispatch }) {
    const { data, status } = await Vue.axios.get("/img_works");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error cargando la lista de imagenes', type: 'error' })
    };
    return { data, status };
  },
  async createImagesWorks({ dispatch }, imagesWorks) {
    const formData = new FormData();
    formData.append('file_works', imagesWorks.file_works);
    formData.append('id_work', imagesWorks.id_work);
    const { data, status } = await Vue.axios.post("/img_works", imagesWorks.formData);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando la imagen', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async updateImagesWorks({ dispatch }, imagesWorks) {
    if (imagesWorks.file_popups)
      formData.append('file_works', imagesWorks.file_works)
    const { data, status } = await Vue.axios.post(`/img_works/${imagesWorks.id}`, imagesWorks.formData);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando la imagen', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async removeImagesWorks({ dispatch }, imagesWorks) {
    const { data, status } = await Vue.axios.delete(`/img_works/${imagesWorks.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminandola imagen seleccionado', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Reel removido correctamente', type: 'success' })
    }
    return { data, status };
  },
  async getListByIdWorks({ dispatch }, id_work) {
    const { data, status } = await Vue.axios.get(`/img_works/id_works/${id_work}`);
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener las imagenes', type: 'error' })
    };
    return { data, status };
  }
}

export default { state, mutations, actions }
