import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async createPathLotAuction({ dispatch }, pathLotAuction) {
        const { data, status } = await Vue.axios.post("/path_lots_auctions", pathLotAuction);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del rubro', type: 'error' })
        }
        return { data, status };
    },
    async removePathLotAuction({ dispatch }, pathLotAuction) {
        const { data, status } = await Vue.axios.delete(`/path_lots_auctions/${pathLotAuction.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al rubro del sistema', type: 'error' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }