import Vue from 'vue'
import Vuex from 'vuex'
import Swal from "sweetalert2"
import { initSessionWatch, clearSessionWatch } from "../common/jwtService"

// modules
import users from "./users";
import areas from "./areas";
import reels from "./reels";
import officials from "./officials";
import aboutUsPg from "./aboutUsPg";
import finalities from "./finalities";
import values from "./values";
import projectsPg from "./projectsPg";
import auctionsPg from './auctionsPg';
import releasesPg from './releasesPg';
import organicStructurePg from './organicStructurePg';
import executionPublicWorksPg from './executionPublicWorksPg';
import administrationSanitizationPg from './administrationSanitizationPg';
import technologicalDevelopmentsPg from './technologicalDevelopmentsPg';
import officialsPg from './officialsPg';
import managedPublicPlaces from './managedPublicPlaces';
import pressNotesPg from './pressNotesPg';
import marksLegalsPg from './marksLegalsPg';
import aboutUsHome from './aboutUsHome';
import someProjects from './someProjects';
import auctions from './auctions';
import auctionContent from './auctionContent';
import pressNotes from './pressNotes';
import releases from './releases';
import files from './files';
import popUps from './popUps';
import districts from './districts';
import lotsAuction from './lotsAuction';
import works from './works';
import publishingFundsPg from './publishingFundsPg';
import books from './books';
import collections from './collections';
import imagesWorks from './imagesWorks';
import googleMaps from './googleMaps';
import pathLotAuctions from './pathLotAuctions';
import transparencyTypes from './transparencyTypes';
import transparencySubTypes from './transparencySubTypes';
import basicInformation from './basicInformation';
import socialMedias from './socialMedias';
import someAuctions from './someAuctions';
import transparencySubtypesTypes from './transparencySubtypesTypes';
import technologicalCategory from './technologicalCategory';
import technologicalDevelopmentsAll from './technologicalDevelopmentsAll';
import technologicalTechnologies from './technologicalTechnologies';
import technologicalDetails from './technologicalDetails';
import denunciationsPg from './denunciationsPg';
import denunciationsQuestions from './denunciationsQuestions';
import denunciationsInformations from './denunciationsInformations';
import denunciations from './denunciations';

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    user: null,
    baseURL: process.env.VUE_APP_API_URL,
    token: localStorage.getItem('token') || null,
    // response
    responseForm: {
      status: 200,
      type: "",
      message: ""
    },
    snackbar: {
      timeout: 2500,
      opened: false,
      message: "",
      color: "success"
    },
  },
  getters: {
    getUser(state) {
      return state.user
    },
    getResponseMessage(state) {
      return state.responseForm
    },
    getSnackBarStatus(state) {
      return state.snackbar
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setResponseForm(state, response) {
      state.responseForm = response
    },
    setToken(state, token) {
      Vue.axios.defaults.headers = {
        "Authorization": "Bearer " + token
      }
      state.token = token
      localStorage.setItem("token", token)
    },
    setSnackBarStatus(state, opts) {
      state.snackbar = opts
    },
  },
  actions: {
    async signIn({ commit, dispatch }, payload) {
      const { data, status } = await Vue.axios.post('/auth/login', payload);
      // console.log({ data, status })
      if (status != 200) {
        dispatch("createResponseMessage", {
          status,
          type: 'error',
          message: data.error || data.message || 'Ocurrió un error intentando validar sus datos, vuelva a intentarlo'
        })
      }
      await dispatch("updateSession", data)
      await dispatch("loadSessionUser")
      return { data, status }
    },
    async refreshToken({ commit, dispatch }) {
      const { data, status } = await Vue.axios.post('/auth/refresh', {}, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      if (status != 200 && status != 201) {
        await dispatch("logout")
      }
      // update token and session
      await dispatch("updateSession", data)
    },
    logout({ commit }) {
      commit("setToken", null)
      localStorage.removeItem("token")
      localStorage.removeItem("expires")
      Vue.axios.defaults.headers = {}
      commit("setUser", null)
      clearSessionWatch()

    },
    updateSession({ commit, dispatch }, session) {
      const expireTime = Date.now() + (session.expires_in * 1000)
      localStorage.setItem("expires", expireTime)
      commit("setToken", session.access_token)
    },
    async loadSessionUser({ commit, dispatch }) {
      const { data, status } = await Vue.axios.post("/auth/me", {}, {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      });
      if (status != 200) return false;
      commit("setUser", data)
      initSessionWatch({
        callback: () => dispatch('refreshToken'),
        paramsCallback: null,
        onErrorCallback: () => dispatch('logout')
      })
      return true
    },
    createResponseMessage({ commit }, response) {
      commit('setResponseForm', response)
    },
    clearResponseMessage({ commit }) {
      commit('setResponseForm', {
        status: 200,
        type: "",
        message: ""
      })
    },
    // MESSAGES
    generateSnackBarMessage({ commit }, { message = "Se guardó correctamente", timeout = 2500, type = "success" }) {
      commit("setSnackBarStatus", {
        timeout: timeout,
        opened: true,
        message: message,
        color: type == "success" ? "success" : "#af0000"
      })
    },
    async generateConfirmDialog({ }, { title = "", message = "¿Estás usted seguro de esto?\nLa siguiente acción eliminará este elemento del sistema", html = null, type = "warning", confirmColor = "#af0000", cancelColor = "#004b86", cancelMessage = "Cancelar", confirmMessage = "Si, quiero eliminar!" }) {
      const val = await Swal.fire({
        //title: `¿Está usted seguro de esto?`,
        text: message,
        icon: type,
        html: html,
        showCancelButton: true,
        confirmButtonColor: confirmColor,
        cancelButtonColor: cancelColor,
        confirmButtonText: confirmMessage,
        cancelButtonText: cancelMessage,
        showCloseButton: true,
      });
      return val.value;
    },
  },
  modules: {
    users,
    areas,
    reels,
    officials,
    aboutUsPg,
    finalities,
    values,
    projectsPg,
    auctionsPg,
    releasesPg,
    organicStructurePg,
    executionPublicWorksPg,
    administrationSanitizationPg,
    technologicalDevelopmentsPg,
    officialsPg,
    managedPublicPlaces,
    pressNotesPg,
    marksLegalsPg,
    aboutUsHome,
    someProjects,
    auctions,
    auctionContent,
    pressNotes,
    releases,
    files,
    popUps,
    works,
    imagesWorks,
    transparencyTypes,
    transparencySubTypes,
    districts,
    lotsAuction,
    publishingFundsPg,
    books,
    collections,
    googleMaps,
    pathLotAuctions,
    someAuctions,
    transparencySubtypesTypes,
    basicInformation,
    socialMedias,
    someAuctions,
    technologicalCategory,
    technologicalDevelopmentsAll,
    technologicalTechnologies,
    technologicalDetails,
    denunciationsPg,
    denunciationsQuestions,
    denunciationsInformations,
    denunciations
  },
})


export default store;
