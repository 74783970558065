import Vue from "vue"
// este store solo me servirá para almacenar funciones

const state = {

}

const mutations = {

}

const actions = {
    async fetchUsers({ dispatch }) {
        const { data, status } = await Vue.axios.get("/users");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error cargando la lista de usuarios', type: 'error' })
        };
        return { data, status };
    },
    async createUser({ dispatch }, user) {
        const { data, status } = await Vue.axios.post("/users", user);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del usuario', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateUser({ dispatch }, user) {
        const { data, status } = await Vue.axios.post(`/users/${user.id}`, user);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del usuario', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeUser({ dispatch }, user) {
        const { data, status } = await Vue.axios.delete(`/users/${user.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al usuario del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Usuario eliminado correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }