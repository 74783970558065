import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
  async fetchTechnologicalCategory({ dispatch }) {
    const { data, status } = await Vue.axios.get("/technological_categories");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener la categoria de la tecnologia', type: 'error' })
    };
    return { data, status };
  },
  async createTechnologicalCategory({ dispatch }, technologicalCategory) {
    const { data, status } = await Vue.axios.post("/technological_categories", technologicalCategory);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando la categoria de la tecnologia', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async updateTechnologicalCategory({ dispatch }, technologicalCategory) {
    const { data, status } = await Vue.axios.post(`/technological_categories/${technologicalCategory.id}`, technologicalCategory);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos de la categoria de la tecnologia', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },
  async removeTechnologicalCategory({ dispatch }, technologicalCategory) {
    const { data, status } = await Vue.axios.delete(`/technological_categories/${technologicalCategory.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando la categoria de la tecnologia seleccionada', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Categoria de la tecnologia removido correctamente', type: 'success' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
