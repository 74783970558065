import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import "./plugins/axios";
import "./plugins/v-md-editor";
import "./plugins/gmap";
import store from './store'
import router from './router'

Vue.config.productionTip = false

const mount = async() => {

    const token = localStorage.getItem("token")

    let isLoggedIn = false

    if (token) {

        store.commit("setToken", token)
        isLoggedIn = await store.dispatch("loadSessionUser")

    }

    if (!isLoggedIn) {
        store.dispatch("logout")
        router.push({ name: 'auth-login' }).catch(err => {})
    }

    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
    }).$mount('#app')

}


mount()