import Vue from "vue"

const state = {}

const mutations = {}

const actions = {
  async fetchSocialMedias({ dispatch }) {
    const { data, status } = await Vue.axios.get("/social_medias");
    if (status != 200) {
      await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener las redes sociales', type: 'error' })
    };
    return { data, status };
  },
  async createSocialMedias({ dispatch }, socialMedias) {
    // console.log(socialMedias);
    const formData = new FormData();
    formData.append('file', socialMedias.file);
    formData.append('description', socialMedias.description);
    formData.append('url_social_medias', socialMedias.url_social_medias);
    for (var pair of formData.entries()) {
      // console.log(pair[0] + ', ' + pair[1]);
    }
    const { data, status } = await Vue.axios.post("/social_medias", formData);
    if (status != 200 && status != 201) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando la red social', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },

  async updateSocialMedias({ dispatch }, socialMedias) {
    const formData = new FormData();
    // console.log(socialMedias);
    formData.append('description', socialMedias.description);
    formData.append('url_social_medias', socialMedias.url_social_medias);
    if (socialMedias.file) {
      formData.append('file', socialMedias.file)
    }
    const { data, status } = await Vue.axios.post(`/social_medias/${socialMedias.id}`, formData);
    if (status != 200 && status != 201 && status != 204) {
      await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando la red social', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
    }
    return { data, status };
  },

  async removeSocialMedias({ dispatch }, socialMedias) {
    const { data, status } = await Vue.axios.delete(`/social_medias/${socialMedias.id}`);
    if (status != 200 && status != 204) {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando la red social del sistema', type: 'error' })
    } else {
      await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
    }
    return { data, status };
  }
}

export default { state, mutations, actions }
