import Vue from 'vue';

const state = {};

const mutations = {};

const actions = {
  async fetchReleases({dispatch}) {
    const {data, status} = await Vue.axios.get ('/releases_card');
    if (status != 200) {
      await dispatch ('generateSnackBarMessage', {
        message: 'Ocurrió un error al obtener el comunicado',
        type: 'error',
      });
    }
    return {data, status};
  },

  async createReleases ({dispatch}, pgReleasesCard) {
    const {data, status} = await Vue.axios.post (
      '/releases_card',
      pgReleasesCard.formData
    );
    if (status != 200 && status != 201) {
      await dispatch ('createResponseMessage', {
        message: data.message || 'Ocurrió un error guardando el comunicado',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },

  async updateReleases ({dispatch}, pgReleasesCard) {
    const {data, status} = await Vue.axios.post (
      `/releases_card/${pgReleasesCard.id}`,
      pgReleasesCard.formData
    );
    if (status != 200 && status != 201 && status != 204) {
      await dispatch ('createResponseMessage', {
        message: data.message ||
          'Ocurrió un error actualizando el comunicado',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Se guardó correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },

  async removeReleases ({dispatch}, pgReleasesCard) {
    const {data, status} = await Vue.axios.delete (
      `/releases_card/${pgReleasesCard.id}`
    );
    if (status != 200 && status != 204) {
      await dispatch ('generateSnackBarMessage', {
        message: data.message ||
          'Ocurrió un error eliminando el comunicado',
        type: 'error',
      });
    } else {
      await dispatch ('generateSnackBarMessage', {
        message: data.message || 'Comunicado eliminado correctamente',
        type: 'success',
      });
    }
    return {data, status};
  },

  async listBySlug ({dispatch}, slug) {
    const {data, status} = await Vue.axios.get (
      `/releases_card/${slug}`
    );
    if (status != 200 && status != 204) {
      await dispatch ('generateSnackBarMessage', {
        message: data.message ||
          'Ocurrió un error eliminando el comunicado',
        type: 'error',
      });
    }
    return {data, status};
  },


};

export default {state, mutations, actions};
