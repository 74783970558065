<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent" class="app-header">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon
            class="d-block d-lg-none me-2"
            @click="isDrawerOpen = !isDrawerOpen"
          ></v-app-bar-nav-icon>
          <!-- <v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field> -->

          <v-spacer></v-spacer>

          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <!-- <v-btn
            icon
            small
            class="ms-3"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn> -->
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0 app-footer">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <span> &copy; 2021 Todos los derechos reservados</span>
          <span class="d-sm-inline">
            Desarrollado por
            <a
              href="https://www.emilima.com.pe/"
              target="_blank"
              class="text--orange text-decoration-none"
              >Emilima</a
            >
          </span>
        </div>
      </div>
    </v-footer>

    <v-snackbar v-model="alert.opened" dark :timeout="alert.timeout" :color="alert.color">
      <span>{{ alert.message }}</span>
    </v-snackbar>
  </v-app>
</template>

<script>
import { ref } from "@vue/composition-api";
import { mdiMagnify, mdiBellOutline, mdiGithub } from "@mdi/js";
import VerticalNavMenu from "./components/vertical-nav-menu/VerticalNavMenu.vue";
import ThemeSwitcher from "./components/ThemeSwitcher.vue";
import AppBarUserMenu from "./components/AppBarUserMenu.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  computed: {
    ...mapGetters({
      alert: "getSnackBarStatus",
    }),
  },
  setup() {
    const isDrawerOpen = ref(null);

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.app-header {
  position: fixed !important;
  top: 0;
}
.app-footer {
  font-size: 14px;
  .text--orange {
    color: orange;
  }

  @media screen and (max-width: 500px) {
    .boxed-container > div {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
    }
  }

  @media screen and (max-width: 340px) {
    font-size: 13px;
  }
}
</style>
