import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchSomeProjects({ dispatch }) {
        const { data, status } = await Vue.axios.get("/some_projects");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los rubros', type: 'error' })
        };
        return { data, status };
    },
    async createSomeProject({ dispatch }, someProjects) {
        const formData = new FormData();
        formData.append('file_some_projects', someProjects.file_some_projects);
        formData.append('category', someProjects.category);
        formData.append('title_some_projects', someProjects.title_some_projects);
        formData.append('descripcion', someProjects.descripcion);
        formData.append('link', someProjects.link);

        const { data, status } = await Vue.axios.post("/some_projects", formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateSomeProject({ dispatch }, someProjects) {
        const formData = new FormData();
        formData.append('category', someProjects.category);
        formData.append('title_some_projects', someProjects.title_some_projects);
        formData.append('descripcion', someProjects.descripcion);
        formData.append('link', someProjects.link);
        if(someProjects.file_some_projects) {
            formData.append('file_some_projects', someProjects.file_some_projects)
        }
        const { data, status } = await Vue.axios.post(`/some_projects/${someProjects.id}`, formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del rubro', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeSomeProject({ dispatch }, someProjects) {
        const { data, status } = await Vue.axios.delete(`/some_projects/${someProjects.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando al rubro del sistema', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Rubro eliminado correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }
