import Vue from "vue"
// este store solo me servirá para almacenar funciones

const state = {}

const mutations = {}

const actions = {
    async fetchReels({ dispatch }) {
        const { data, status } = await Vue.axios.get("/reels");
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error cargando la lista de reels', type: 'error' })
        };
        return { data, status };
    },
    async createReel({ dispatch }, reel) {
        const { data, status } = await Vue.axios.post("/reels", reel.formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del reel', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateReel({ dispatch }, reel) {
        const { data, status } = await Vue.axios.post(`/reels/${reel.id}`, reel.formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del reel', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeReel({ dispatch }, reel) {
        const { data, status } = await Vue.axios.delete(`/reels/${reel.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el reel seleccionado', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Reel removido correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }