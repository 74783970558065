import Vue from "vue"

const state = {

}

const mutations = {

}

const actions = {
    async fetchSomeAuctionByAuction({ dispatch }, idAuction) {
        const { data, status } = await Vue.axios.get(`/some_auctions/auction/${idAuction}`);
        if (status != 200) {
            await dispatch("generateSnackBarMessage", { message: 'Ocurrió un error al obtener los card para promocionar lotes', type: 'error' })
        };
        return { data, status };
    },
    async createSomeAuctions({ dispatch }, someAuctions) {
        const { data, status } = await Vue.axios.post("/some_auctions", someAuctions.formData);
        if (status != 200 && status != 201) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error guardando los datos del someAuctions', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async updateSomeAuctions({ dispatch }, someAuctions) {
        const { data, status } = await Vue.axios.post(`/some_auctions/${someAuctions.id}`, someAuctions.formData);
        if (status != 200 && status != 201 && status != 204) {
            await dispatch("createResponseMessage", { message: data.message || 'Ocurrió un error actualizando los datos del someAuctions', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Se guardó correctamente', type: 'success' })
        }
        return { data, status };
    },
    async removeSomeAuctions({ dispatch }, someAuctions) {
        const { data, status } = await Vue.axios.delete(`/some_auctions/${someAuctions.id}`);
        if (status != 200 && status != 204) {
            await dispatch("generateSnackBarMessage", { message: data.message || 'Ocurrió un error eliminando el someAuctions seleccionado', type: 'error' })
        } else {
            await dispatch("generateSnackBarMessage", { message: data.message || 'SomeAuctions removido correctamente', type: 'success' })
        }
        return { data, status };
    }
}

export default { state, mutations, actions }